import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthLoginResponse } from '../models';
// @ts-ignore
import { AuthMyDetailResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login
         * @summary Login
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuth: async (redirectUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('loginAuth', 'redirectUri', redirectUri)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout
         * @summary Logout
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuth: async (refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('logoutAuth', 'refreshToken', refreshToken)
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get My JWT Detail
         * @summary Get My JTW Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myDetailAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/my-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication securityAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "securityAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Renew by refresh token
         * @summary Renew
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenAuth: async (refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshTokenAuth', 'refreshToken', refreshToken)
            const localVarPath = `/auth/renew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Token
         * @summary Get Token
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuth: async (code: string, redirectUri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('tokenAuth', 'code', code)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('tokenAuth', 'redirectUri', redirectUri)
            const localVarPath = `/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Login
         * @summary Login
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuth(redirectUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuth(redirectUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logout
         * @summary Logout
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutAuth(refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAuth(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get My JWT Detail
         * @summary Get My JTW Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myDetailAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMyDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myDetailAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Renew by refresh token
         * @summary Renew
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokenAuth(refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokenAuth(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Token
         * @summary Get Token
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenAuth(code: string, redirectUri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenAuth(code, redirectUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Login
         * @summary Login
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuth(redirectUri: string, options?: any): AxiosPromise<AuthLoginResponse> {
            return localVarFp.loginAuth(redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout
         * @summary Logout
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuth(refreshToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.logoutAuth(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get My JWT Detail
         * @summary Get My JTW Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myDetailAuth(options?: any): AxiosPromise<AuthMyDetailResponse> {
            return localVarFp.myDetailAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * Renew by refresh token
         * @summary Renew
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenAuth(refreshToken: string, options?: any): AxiosPromise<string> {
            return localVarFp.refreshTokenAuth(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Token
         * @summary Get Token
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuth(code: string, redirectUri: string, options?: any): AxiosPromise<string> {
            return localVarFp.tokenAuth(code, redirectUri, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Login
     * @summary Login
     * @param {string} redirectUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAuth(redirectUri: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAuth(redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout
     * @summary Logout
     * @param {string} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutAuth(refreshToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).logoutAuth(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get My JWT Detail
     * @summary Get My JTW Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public myDetailAuth(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).myDetailAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Renew by refresh token
     * @summary Renew
     * @param {string} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshTokenAuth(refreshToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshTokenAuth(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Token
     * @summary Get Token
     * @param {string} code 
     * @param {string} redirectUri 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public tokenAuth(code: string, redirectUri: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).tokenAuth(code, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }
}
