import { createApp as createClientApp, h, Suspense } from "vue";

import { createHead } from "@vueuse/head";
import { createPinia } from "pinia";
import { createI18n } from "./i18n";
import { createRouter } from "./router";
import VueroApp from "./VueroApp.vue";
import "./styles";
import * as basets from "../src/client/base";

import { initDarkmode } from "/@src/stores/darkmode";
import { createApi } from "/@src/composable/useApi";
import Pivot from "vue-flexmonster/vue3";
import { Notyf } from "notyf";
import { useUserSession } from "./stores/userSession";
import "flexmonster/flexmonster.css";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";

const notyf = new Notyf({
  types: [
    {
      type: "info",
      background: "#8ec3fb",
      icon: false,
    },
  ],
});

window.onstorage = (e) => {
  if (e.key === "token" && e.newValue !== e.oldValue) {
    const { setAppCtxDirty } = useUserSession();
    setAppCtxDirty();
  }
};

document.addEventListener("visibilitychange", (e: any) => {
  if (document.visibilityState === "visible") {
    const { dirtyAppContext } = useUserSession();
    if (dirtyAppContext) {
      window.location.reload();
    }
  }
});

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>;

import {
  registerGlobalComponents,
  registerRouterNavigationGuards,
} from "./app-custom";
import axios from "axios";
import { basicSetup, EditorView } from 'codemirror'
import VueCodemirror from 'vue-codemirror'
import { json } from '@codemirror/lang-json'

export async function createApp() {
  const head = createHead();
  const i18n = createI18n();
  const router = createRouter();
  const pinia = createPinia();
  const api = createApi();
  const amsEnv = document
    .getElementsByName("AMS_ENV")[0]
    .getAttribute("content")
    ?.toString()
    .replace(/\s/g, "");
  const sentryDsn = document
    .getElementsByName("SENTRY_DSN")[0]
    .getAttribute("content")
    ?.toString()
    .replace(/\s/g, "");
  const amsRevision = document
    .getElementsByName("AMS_REVISION")[0]
    .getAttribute("content")
    ?.toString()
    .replace(/\s/g, "");
  const amsDeploy = document
    .getElementsByName("AMS_DEPLOY")[0]
    .getAttribute("content")
    ?.toString()
    .replace(/\s/g, "");
  const amsName = document
    .getElementsByName("AMS_NAME")[0]
    .getAttribute("content")
    ?.toString()
    .replace(/\s/g, "");
  const enviroments = [] //["be", "fe", "sit", "uat", "fot"];
  const app = createClientApp({
    // This is the global app setup function

    setup() {
      /**
       * Initialize the darkmode watcher
       *
       * @see /@src/stores/darkmode
       */
      initDarkmode();

      /**
       * Here we are creating a render function for our main app with
       * the main VueroApp component, wrapped in a Suspense component
       * to handle async loading of the app.
       * Template equivalent would be:
       *
       * <template>
       *   <Susupense>
       *     <VueroApp />
       *   </Susupense>
       * </template>
       */

      return () => {
        /**
         * The Suspense component is needed to use async in child components setup
         * @see https://v3.vuejs.org/guide/migration/suspense.html
         */

        return h(Suspense, null, {
          default: () => h(VueroApp),
        });
      };
    },
  });

  app.use(Pivot);

  if (enviroments.includes(<string>amsEnv)) {
    Sentry.init({
      app,
      dsn: sentryDsn,
      environment: amsEnv,
      release: `${amsName}@${amsRevision}`,
      dist: amsDeploy,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
      ],
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    Sentry.setTags({ revision: amsRevision, deploy: amsDeploy });
  }

  const vuero = {
    app,
    api,
    router,
    i18n,
    head,
    pinia,
  };

  await registerGlobalComponents(vuero);
  app.use(vuero.pinia);
  app.use(vuero.head);
  app.use(vuero.i18n);

  registerRouterNavigationGuards(vuero);
  app.use(vuero.router);
  app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: false,
  position: POSITION.BOTTOM_CENTER,
  timeout: 10000,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  icon: false,
});

  app.use(VueCodemirror, {
  // optional default global options
  disabled: false,
  indentWithTab: true,
  tabSize: 2,
  extensions: [basicSetup, json(), EditorView.lineWrapping]
})

  return vuero;
}
