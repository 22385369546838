import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as apis from "/@src/client/api";
import jwtDecode from "jwt-decode";

export const BASE_PATH = document.getElementsByName('IS_API_URL')[0].getAttribute('content').replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
        axios.interceptors.request.use(async function (config) {
            const accessToken = localStorage.getItem("access_token") as string
            if (accessToken && accessToken.length > 0) {
			const urlWithoutQueryParams = config.url?.split('?')[0]
            const pathsWithoutAuthetication = ['auth/login', 'auth/logout', 'auth/token', 'auth/renew']
            const completePathsWithoutAuthetication = pathsWithoutAuthetication.map((value) => {
                return basePath + '/' + value
            })
			if(!completePathsWithoutAuthetication.includes(urlWithoutQueryParams)) {
				config.headers['Authorization'] = 'Bearer ' + accessToken;
				const { exp } = jwtDecode(accessToken)
				const now = Date.now() / 1000 // exp is represented in seconds since epoch
				const timeUntilRefresh = exp - now
				//Refresh 10 seconds before it expires 
				if (timeUntilRefresh < 10 && accessToken.length > 0 ) {
					const userDetail = new apis.AuthApi()
					const refreshToken = localStorage.getItem("refresh_token") as string
					localStorage.setItem('access_token', '');
						await userDetail.refreshTokenAuth(refreshToken, {
												headers: {
													Authorization: ''
												}
												}).then((refreshTokenResponse) => {
							localStorage.setItem('access_token', refreshTokenResponse.data.access_token);
							localStorage.setItem('refresh_token', refreshTokenResponse.data.refresh_token);
							// insert new token to request
							config.headers['Authorization'] = 'Bearer ' + refreshTokenResponse.data.access_token;
						}).catch((error) => {
							console.log('bola chyba')
						})
					}
				}
            }
            return config;
        }, async function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            return response;
        },  function (error) {
           const originalConfig = error.config;
            if (error.response && error.response.status === 403 && !originalConfig._retry) {
                originalConfig._retry = true;
                const userDetail = new apis.AuthApi()
                const refreshToken = localStorage.getItem("refresh_token") as string

                userDetail.refreshTokenAuth(refreshToken, {
                                        headers: {
                                            Authorization: ''
                                        }
                                        }).then((refreshTokenResponse) => {
                     localStorage.setItem('access_token', refreshTokenResponse.data.access_token);
                     localStorage.setItem('refresh_token', refreshTokenResponse.data.refresh_token);
                     const token = localStorage.getItem('access_token');
                     axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                     originalConfig.headers['Authorization'] = 'Bearer ' + token;
                     return axios(originalConfig);
                })
            } else if(error.response && error.response.status === 424 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    const locationURL = location.protocol + '//' + location.host;
                    const apiGet = new apis.AuthApi();
                    apiGet.loginAuth(locationURL+'/callback')
                      .then(response => {
                          localStorage.setItem('access_token', '');
                          localStorage.setItem('refresh_token', '');
                          window.location.replace(response.data.url);
                      })
                      .catch(error => {
                          console.log(error);
                      })
            }
            return Promise.reject(error);
        });
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}
