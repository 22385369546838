<script setup lang="ts">
import * as globalRules from "@vee-validate/rules";
import { configure, defineRule } from "vee-validate";
import sk from "@vee-validate/i18n/dist/locale/sk.json";
import { localize, setLocale } from "@vee-validate/i18n";
import { setLocale as setValidationLocales } from "yup";
setValidationLocales({
  mixed: {
    required: (value) => {
      return "Položka je povinná";
    },
  },
});

configure({
  generateMessage: localize({
    sk,
  }),
});
setLocale("sk");

localize("sk", {
  messages: {
    digits: "Číslo alebo číslo s presnosťou na 2 desatinné miesta",
    rodneCislo: "Rodné číslo zadávajte v tvare bez '/'",
    telefonneCislo:
      "Nesprávne telefónne číslo (zadať predčíslie štátu.  Napr. Slovensko  +421…)",
    orientacneCislo: "Orientačné číslo nemôže obsahovať špeciálne znaky.",
    dphVatNumber: "IČ DPH/VAT musí začínať SK... a obsahovat od 1 po 20 číslic",
    requiredIcDph: "Položka {field} je povinná",
    isInyDovodUzatvoreniaRequired: "Položka {field} je povinná",
    dateTimeBiggerThenActualDateTime:
      "Dátum a čas publikovania nemôže byť zvolený spätne.",
    currentDateLessThenActualDateTime:
      "Dátum musí byť dnešný alebo zvolený spätne.",
    min: "Položka {field} musí obsahovať minimálne 0:{length} znakov",
    checkDuplicityVyzvaByKod: "Zadaný {field} už bol vytvorený",
    checkDuplicityExistingVyzvaByKod: "Zadaný {field} už bol vytvorený",
    checkDuplicityPouzivatel: "Zadaný {field} už bol vytvorený",
  },
});

defineRule("email", globalRules.email);
defineRule("max", globalRules.max);
defineRule("min", globalRules.min);
defineRule("telefonneCislo", globalRules.regex);
defineRule("supisneCislo", globalRules.regex);
defineRule("orientacneCislo", globalRules.regex);
defineRule("dphVatNumber", globalRules.regex);
defineRule("rodneCislo", globalRules.regex);
defineRule("numeric", globalRules.numeric);
defineRule("digits", globalRules.regex);
defineRule("kvartalRokSplnenia", globalRules.regex);
defineRule("web", globalRules.regex);

defineRule("dateTimeBiggerThenActualDateTime", (value: any) => {
  if (value != undefined && new Date(value).getTime() <= new Date().getTime()) {
    return false;
  } else {
    return true;
  }
});

defineRule("currentDateLessThenActualDateTime", (value: any) => {
  if (value != undefined && new Date(value).getTime() >= new Date().getTime()) {
    return false;
  } else {
    return true;
  }
});

defineRule(
  "dateLessThan",
  (value: string, params: Array<any>, ctx: Record<string, any>) => {
    const date2str = ctx.form[params[0]];
    if (!value || !date2str) {
      return true;
    }
    try {
      const date2 = new Date(date2str);
      date2.setHours(0, 0, 0);
      const date1 = new Date(value);
      date1.setHours(0, 0, 0);
      return date1.getTime() <= date2.getTime() ? true : params[1];
    } catch (err) {
      console.error(err);
      return false;
    }
  }
);

defineRule("requiredIcDph", (value: any) => {
  if (!value || !value.length) {
    return false;
  }
  return true;
});

defineRule(
  "dateGreaterThan",
  (value: string, params: Array<any>, ctx: Record<string, any>) => {
    const date2str = ctx.form[params[0]];
    if (!value || !date2str) {
      return true;
    }
    try {
      const date2 = new Date(date2str);
      date2.setHours(0, 0, 0);
      const date1 = new Date(value);
      date1.setHours(0, 0, 0);
      return date1.getTime() > date2.getTime() ? true : params[1];
    } catch (err) {
      console.error(err);
      return false;
    }
  }
);

defineRule("pscAbrValidate", (value: any) => {
  console.log(value);
  if (!value || !value.length || value.length > 10) return false;
  return true;
});
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Transition name="fade-slow" mode="out-in">
      <component :is="Component" />
    </Transition>
  </RouterView>
  <!-- <VReloadPrompt app-name="Vuero" />-->
</template>
